@charset "UTF-8";

@import "../mixin";

.cmn_header {
  position: relative;
  border-top: 5px solid $colorSidenav;
  height: 160px;
  box-sizing: border-box;
  box-shadow: 2px 0 5px 0 rgba(0, 0, 0, .3);
  z-index: 40;
  @include sp {
    border-top-width: 3px;
    height: auto;
  }
  &--inner {
    position: relative;
    display: block;
    max-width: 960px;
    width: 100%;
    margin: 0 auto;
  }
  &--name {
    @include sp {
      border-bottom: 1px solid #f2f2f2;
    }
  }
  &--logo_kp {
    display: block;
    width: 225px;
    padding: 15px 0 0 0;
    @include sp {
      width: 160px;
      padding: 5px 0 5px 20px;
    }
  }
  &--counter {
    position: absolute;
    top:20px;
    left:270px;
    z-index: 10; //検索窓の上へ。検索窓クリック時にz-index解除（common.js参照）
    @include sp {
      top:65px;
      left:20px;
    }
  }
  &--counter_ttl {
    @include fz(13);
    margin: 0 0 5px 0;
    @include sp {
      @include fz(12);
    }
  }
  /*
  &--counter_link {
    position: relative;
    display: block;
    text-decoration: none;
    color: $colorSidenav;
    &:before {
      content: "";
      position: absolute;
      top:0;
      bottom: 0;
      margin: auto 0;
      left: -22px;
      width: 16px;
      height: 22px;
      border-radius: 5px;
      background-color: transparent;
      transition: .15s;
    }
    &:after {
      content: "";
      position: absolute;
      top:0;
      bottom: 0;
      margin: auto 0;
      left: -20px;
      width: 6px;
      height: 6px;
      border-top: 2px solid $colorSidenav;
      border-right: 2px solid $colorSidenav;
      transform: rotate(45deg);
    }
    &:hover {
      &:before {
        background-color: $colorYellow;
      }
    }
    @include sp {
      &:after {
        left: -10px;
      }
    }
  }
  &--counter_num {
    display: inline-block;
    @include fz(34);
    font-weight: 700;
  }
  &--counter_unit {
    display: inline-block;
  }
  */
}

.cmn_header_nav {
  position: absolute;
  top:10px;
  right:0;
  display: flex;
  @include sp {
    display: none;
  }
  &--link {
    position: relative;
    display: inline-block;
    color: #7b7b7b;
    @include fz(12);
    text-decoration: none;
    padding: 0 15px 0 5px;
    transition: .15s;
    &:before {
      content: "";
      position: absolute;
      top:0;
      bottom: 0;
      margin: auto 0;
      left: -5px;
      width: 4px;
      height: 4px;
      border-top: 1px solid #7b7b7b;
      border-right: 1px solid #7b7b7b;
      transform: rotate(45deg);
    }
    &:last-child {
      padding-right: 0;
    }
  }
  &:hover {
    color: #000;
  }
}

.cmn_header_user {
  position: absolute;
  top:40px;
  right:0;
  display: flex;
  align-items: center;
  @include sp {
    top:5px;
    right:10px;
  }
  &--box {
    color: #646464;
    @include sp {
      position: absolute;
      top:0;
      right:0;
      white-space: nowrap;
    }
    &-login {
      margin-right:10px;
      @include sp {
        width: auto;
        margin-right:0;
      }
    }
    &-logout {
    }
  }
  &--name {
    @include fz(16);
    line-height: 1.2;
    font-weight: 700;
    @include sp {
      @include fz(12);
    }
  }
  &--dear {
    @include fz(16);
    padding: 0 0 0 5px;
    @include sp {
      @include fz(12);
    }
  }
  &--config {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 30px;
    color: #646464;
    @include fz(16);
    text-decoration: none;
    &:before {
      @include icon2;
      content: $icon-config;
      @include fz(22);
      margin-right:10px;
    }
    @include sp {
      @include fz(12);
      margin: 20px 10px 10px 0;
      padding-left:0;
      &:before {
        position: relative;
        vertical-align: middle;
        @include fz(16);
        margin-right:5px;
      }
    }
  }
  &--btn {
    position: relative;
    width: 155px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include fz(14);
    text-decoration: none;
    transition: .15s;
    &:before {
      @include icon2;
      @include fz(18);
      margin:0 10px 0 0;
    }
    @include sp {
      height: 26px;
      @include fz(12);
      margin: 10px auto;
      &:before {
        @include fz(14);
        margin-right:5px;
      }
    }
    &-login {
      background-color: #8b7d75;
      border-radius: 5px;
      color: #fff;
      &:before {
        content: $icon-login;
      }
      &:hover {
        background-color: #6e5d53;
      }
      @include sp {
        width: 102px;
        margin-top: 20px;
      }
    }
    &-logout {
      border: 1px solid #d5d5d5;
      background-color: #fff;
      box-sizing: border-box;
      border-radius: 5px;
      color: #646464;
      &:before {
        content: $icon-logout;
      }
      &:hover {
        background-color: #e6e6e6;
      }
      @include sp {
        border: none;
        width: 95px;
        border-radius: 0;
        border-right:1px solid #ccc;
        border-left:1px solid #ccc;
        margin: 20px auto 10px auto;
      }
    }
    
  }
}


.cmn_header_search {
  border-top: 1px solid #f2f2f2;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  left:0;
  right:0;
  @include sp {
    position: relative;
  }
  &--inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 960px;
    height: 58px;
    margin: 0 auto;
    border-bottom: 2px solid $colorOrange;
    @include sp {
      justify-content: flex-end;
      width: 100%;
      height: 56px;
      border-bottom: 1px solid #f2f2f2;
    }
  }
  &--link {
    position: relative;
    display: flex;
    align-items: center;
    width: 200px;
    height: 58px;
    padding-left:30px;
    box-sizing: border-box;
    @include fz(14);
    text-decoration: none;
    color: #fff;
    background-color: $colorOrange;
    background-image: url("/container/common/images/cmn-img-cooker.png");
    background-position: 120px 6px;
    background-repeat: no-repeat;
    transition: .15s;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 20px;
      margin: auto 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 0 6px 6px;
      border-color: transparent transparent transparent #fff;
    }
    &:hover {
      background-color: $colorYellow;
      color: $colorBrown;
      &:before {
        border-color: transparent transparent transparent $colorBrown;
      }
    }
    @include sp {
      display: none;
    }
  }
  &--type {
    display: flex;
    align-items: center;
    @include sp {
      display: none;
    }
  }
  &--type_txt {
    @include fz(14);
    display: inline-block;
    margin: 0 10px 0 0;
  }
  &--select {
    padding: 0 15px;
    position: relative;
    //background-color: #eeeae6;
    border:1px solid #ccc;
    border-radius: 5px;
    min-width: 170px;
		overflow: hidden;
    box-sizing: border-box;
    height: 36px;
    &:after {
      position: absolute;
      top: calc(50% - 3px);
      right: 15px;
      content: "";
      display: block;
      border-style: solid;
      border-width: 6px 6px 0 6px;
      border-color: #646464 transparent transparent transparent;
    }
  }
  &--select_tag {
    @include fz(14);
    position: relative;
    background-color: transparent;
    height: 36px;
    -webkit-appearance: none;
    width: 120%;
    left:-5%;
    z-index: 1;
    color: #949494;
    border: 0;
  }
  &--box {
  }
  &--label {
    @include fz(14);
    margin: 0 10px 0 0;
    color: $colorBrown;
  }
  &--link_btn {
    @include fz(14);
    position: relative;
    display: flex;
    align-items: center;
    width: 170px;
    height: 58px;
    padding: 0 0 0 30px;
    color: $colorBrown;
    text-decoration: none;
    box-sizing: border-box;
    border-right: 1px solid #f2f2f2;
    border-left: 1px solid #f2f2f2;
    box-sizing: border-box;
    &:after {
      position: absolute;
      top: 5px;
      left: 5px;
      opacity: 0;
      background-color: #c89b05;
      content: "";
      display: block;
      width: calc(100% - 10px);
      height: calc(100% - 10px);
      border-radius: 5px;
      transform: scale(.9, .9);
      transition: .15s;
    }
    &:hover {
      &:after {
        opacity: .3;
        transform: scale(1, 1);
      }
    }
    @include sp {
      display: none;
    }
  }
	&--link_btn_cross {
		display: inline-block;
		position: absolute;
		top: calc(50% - 8px);
		right: 35px;
		width: 16px;
		height: 16px;
		border-radius: 3px;
		background-color: $colorOrange;
    &:before,
		&:after {
			position: absolute;
			top: 7px;
			left:3px;
			content: "";
			display: block;
			width: 10px;
			height: 2px;
			background: #fff;
    }
    &:after {
			transform: rotate(-90deg)
    }
		&.active {
      &:before {
        display: none;
      }
      &:after {
        transform: rotate(0deg);
      }
    }
	}
}

.gl_search_detail {
  opacity: 0;
  visibility: hidden;
  transition: .3s;
  position: absolute;
  left: 0;
  right:0;
  top: 60px;
  width: 960px;
  margin: 0 auto;
  &.active {
    opacity: 1;
    visibility: visible;
    z-index:130;
    top: 70px;
  }
  &--bg {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    transition: opacity .3s;
    z-index:1;
    top: 60px;
    left:0;
    width:100%;
    background-image: url("/container/common/images/bg-main-content.png");
    &.active {
      opacity: 1;
      visibility: visible;
      z-index: 9;
    }
  }
  &--in {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 10px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  }
  &--intro {
    @include fz(12);
    line-height: 1.4;
    color: $colorBrown;
    padding: 20px 0;  
  }
  &--row {
    width: 895px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    border-bottom: 1px solid #efebe8;
    padding: 5px 0 10px 0;
  }
  &--l {
    font-weight: 700;
    color: $colorSidenav;
    width: 180px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @include fz(15);
  }
  &--r {
    width: 715px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @include fz(14);
  }
  &--label {
    margin: 0 15px 5px 0;
    padding: 0;
    color: #505050;
    cursor: pointer;
    transition: .15s;
    &:hover {
      color: darken($colorYellow, 10%);
    }
  }
  &--radiobox {
    position: relative;
    top: 5px;
    border: 1px solid #505050;
    appearance: none;
    cursor: pointer;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    transition: .15s;
    &:after {
      position: absolute;
      top: 2px;
      left: 2px;
      background-color: darken($colorYellow, 10%);
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      transform: scale(0, 0);
    }
    &:checked {
      border-radius: 50%;
      &:after {
        animation: inputFade .2s cubic-bezier(.895, .03, .685, .22) forwards;
      }
    }
    @keyframes inputFade {
      0% {
        transform: scale(0, 0);
      }
      100% {
        transform: scale(1, 1);
      }
    }
  }
  &--checkbox {
    position: relative;
    top: 5px;
    border: 1px solid #505050;
    appearance: none;
    cursor: pointer;
    width: 16px;
    height: 16px;
    transition: .15s;
    &:after {
      position: absolute;
      top: 2px;
      left: 2px;
      background-color: darken($colorYellow, 10%);
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      transform: scale(0, 0);
    }
    &:checked {
      &:after {
        animation: inputFade .2s cubic-bezier(.895, .03, .685, .22) forwards;
      }
    }
    @keyframes inputFade {
      0% {
        transform: scale(0, 0);
      }
      100% {
        transform: scale(1, 1);
      }
    }
  }
  &--select_box {
    margin: 5px 3px 0 0;
    position: relative;
    display: inline-block;
    top: 0;
    background-color: #eeeae6;
    height: 34px;
    width: 190px;
		overflow: hidden;
    &:before {
      position: absolute;
      top: 8px;
      right: 10px;
      content: "";
      display: block;
      border-style: solid;
      border-width: 0 6px 6px 6px;
      border-color: transparent transparent #725c50 transparent;
    }
    &:after {
      position: absolute;
      right: 10px;
      bottom: 8px;
      content: "";
      display: block;
      border-style: solid;
      border-width: 6px 6px 0 6px;
      border-color: #725c50 transparent transparent transparent;
    }
    &:nth-child(3n) {
      margin-right: 0;
    }
    &-wide {
      width: 290px;
    }
  }
  &--select {
    @include fz(14);
    padding: 5px 10px;
    background-color: transparent;
    appearance: none;
    width: 290px;
    box-sizing: border-box;
    border: 0;
    height: 34px;
		overflow: hidden;
    &-wide {
      width: 390px;
    }
  }
  &--input_box {
    display: inline-block;
  }
  &--input {
    display: inline-block;
    @include fz(14);
    margin: 5px 10px 0 0;
    padding: 5px 10px;
    background-color: #eeeae6;
    width: 190px;
    border: 0;
    height: 34px;
    box-sizing: border-box;
    z-index: 100;
    position: relative;
  }
  &--type {
    display: inline-block;
    @include fz(18);
    position: relative;
    top: 5px;
    font-weight: 700;
  }
  &--txt {
    display: inline;
    margin: 0 0 0 10px;
    position: relative;
    top: 4px;
    line-height: 1.3;
  }
  &--submit_box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px 0;
    background-color: $colorYellow;
    width: 400px;
    height: 60px;
    border-radius: 30px;
    transition: .15s;
    &:after {
      content: "";
      position: absolute;
      top:0;
      bottom:0;
      right: 30px;
      width: 7px;
      height: 7px;
      margin: auto 0;
      border-top: 2px solid $colorBrown;
      border-right: 2px solid $colorBrown;
      transform: rotate(45deg);
      transition: .15s;
    }
    &:hover {
      filter: contrast(120%);
      box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.3);
    }
  }
  &--submit {
    @include fz(18);
    font-weight: 700;
    background-color: transparent;
    width: 100%;
    border: 0;
    color: $colorBrown;
    appearance: none;
    height: 60px;
    cursor: pointer;
    transition: .15s;
  }
}
