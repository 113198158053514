@charset "UTF-8";

@import "../mixin";

.gl_footer {
  @include sp {
    margin-bottom: 60px;
  }
  &--top_btn {
    position: fixed;
    right: 25px;
    bottom: 25px;
    background-color: rgba(62, 37, 22, .6);
    width: 70px;
    height: 70px;
    text-decoration: none;
    transition: .15s;
    border-radius: 50%;
    color: #fff;
		z-index: 120;
    &:before {
      content: "";
      position: absolute;
      top: calc(50% - 6px);
      left: 0;
      right: 0;
      width: 16px;
      height: 16px;
      border-top: 5px solid #fff;
      border-right: 5px solid #fff;
      transform: rotate(-45deg);
      margin: 0 auto;
      display: block;
    }
    &:hover {
      background: rgba(62, 37, 22, .9);
    }
    @include sp {
      right:0;
      bottom: 0;
      width: 70px;
      height: 60px;
      border-radius: 0;
      background-color: $colorSidenav;
      &:before {
        width: 10px;
        height: 10px;
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
        top:20px;
      }
      &:after {
        content: "Page Top";
        @include fz(10);
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        padding: 0 0 10px 0;
        height: 100%;
        box-sizing: border-box;
      }
    }
  }
  &--top {
    background-image: url("/container/common/images/bg-main-content-footer.png");
  }
  &--bottom {
    padding: 25px 0 15px;
    border-bottom: 1px solid #ccc;
		background: #fff;
    @include sp {
      padding: 0;
    }
  }
  &--in {
    position: relative;
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    @include sp {
      padding: 20px 10px;
      justify-content: space-between;
    }
  }
  &--request_btn {
    position: relative;
    display: block;
    @include fz(14);
    text-align: center;
    color: #fff;
    text-decoration: none;
    width: 170px;
    height: 90px;
    padding: 13px 0 0 0;
    box-sizing: border-box;
    background-color: $colorOrange;
    box-shadow: 0 2px 0 #b55e33;
    border-radius: 5px;
    transition: .15s;
    &:before {
      @include icon2;
      @include fz(20);
      margin: 10px 0;
      content: $icon-catalog;
      display: block;
    }
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      transition: .15s;
      width: 100%;
      border-radius: 5px;
      height: 100%;
      box-shadow: 0 0 0 #b55e33 inset;
    }
    &:hover {
      top: 2px;
      background-color: darken(#f6883f, 5%);
      box-shadow: 0 0 0 #b55e33;
      &:after {
        box-shadow: 0 2px 0 #b55e33 inset;
      }
    }
    @include sp {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top:0;
      padding-left:30px;
      width: 100%;
      height: 50px;
      margin: 0 auto 20px auto;
      &:before {
        margin-right:10px;
        margin-left:-40px;
      }
    }
  }
  &--copy_text {
    @include fz(12);
    margin: 0 0 0 auto;
    text-align: right;
    display: inline-block;
    @include sp {
      margin: 0;
    }
  }
  &--logo {
    @include fz(18);
    margin: 0 0 0 20px;
    text-decoration: none;
    color: #505050;
    transition: .15s;
    &:before {
      @include icon2;
      content: $icon-logo;
    }
    &:hover {
      color: $colorYellow;
    }
  }
}

.gl_footer_nav {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 195px);
  margin: 0 0 0 25px;
  @include sp {
    width: 100%;
    margin-left:0;
  }
  &--link_btn {
    position: relative;
    display: block;
    padding: 33px 0 0 0;
    text-align: center;
    height: 150px;
    width: 25%;
    box-sizing: border-box;
    text-decoration: none;
    border-left: 1px solid rgba(255, 255, 255, .3);
    box-sizing: border-box;
    transition: .15s;
    &:last-child {
      border-right: 1px solid rgba(255, 255, 255, .3);
    }
    &:after {
      content: "";
      position: absolute;
      top:15px;
      left:0;
      right:0;
      margin: 0 auto;
      display: block;
      width: 65px;
      height: 65px;
      border-radius: 50%;
      background-color: #eadccb;
      z-index: 1;
    }
    &:before {
      position: relative;
      @include icon2;
      @include fz(30);
      margin: 0 0 30px 0;
      display: block;
      transition: .15s;
      color: #6d3315;
      width: 100%;
      z-index: 3;
    }
    &:hover {
      background-color: rgba(0, 0, 0, .15);
      .gl_footer_nav {
        &--ttl {
          color: #fff;
        }
      }
    }
    @include sp {
      width: 50%;
      border-bottom: 1px solid rgba(255, 255, 255, .3);
      &:last-child {
        border-right: none;
      }
      &:nth-child(2n-1) {
        border-left: none;
      }
      &:nth-child(3),
      &:nth-child(4){
        border-bottom: none;
      }
      
    }
    &-catalog {
      &:before {
        content: $icon-everio;
      }
    }
    &-experience {
      &:before {
        content: $icon-testkitchen;
      }
    }
    &-faq {
      &:before {
        content: $icon-faq;
      }
    }
    &-contact {
      &:before {
        content: $icon-contact;
      }
    }
  }
  &--ttl {
    margin: 0 0 10px 0;
    @include fz(16);
    color: #eadccb;
    display: block;
    width: 100%;
    transition: .15s;
    @include sp {
      @include fz(14);
    }
  }
  &--catch {
    @include fz(14);
    color: #e8e3df;
    width: 100%;
    display: block;
    &:before {
      margin: 0 5px 0 0;
      position: relative;
      top: 1px;
      content: "";
      display: inline-block;
      border-style: solid;
      border-width: 6px 0 6px 6px;
      border-color: transparent transparent transparent #e8e3df;
    }
    @include sp {
      @include fz(12);
    }
  }
}

.gl_footer_bottom_nav {
  padding: 15px 0;
	background: #fff;
  &--in {
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    @include sp {
      padding-right:15px;
    }
  }
  &--link_btn {
    @include fz(12);
    padding: 0 15px;
    color: #000;
    display: block;
    text-decoration: none;
    border-right: 1px solid #000;
    transition: .15s;
    &:first-child {
      border-left: 1px solid #000;
    }
    &:hover {
      color: $colorYellow;
    }
  }
}
