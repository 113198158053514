@charset "UTF-8";

@import "mixin";
@import "common/gl-header";
@import "common/gl-footer";
@import "common/gnav";

@font-face {
	font-family: "icomoon";
	font-weight: normal;
	font-style: normal;
  font-display: block;
  //新アイコン
  src: url('/container/common/fonts/icomoon.eot?11ivf4');
  src: url('/container/common/fonts/icomoon.eot?11ivf4#iefix') format('embedded-opentype'),
  url('/container/common/fonts/icomoon.ttf?11ivf4') format('truetype'),
  url('/container/common/fonts/icomoon.woff?11ivf4') format('woff'),
  url('/container/common/fonts/icomoon.svg?11ivf4#icomoon') format('svg');
}

//基本のコンテンツ枠
.contents {
  position: relative;
  width: 100%;
  &-cmn {
    background-image: url("/container/common/images/bg-main-content.png");
    //SPで最下にぱんくず入れ
    @include sp {
      padding-bottom:30px;
    }
  }
  //サイトトップ
  &-index {
    background-image: url("/container/common/images/bg-main-content-base.png");
  }
  &--inner {
    width: 960px;
    margin: 0 auto;
    @include sp {
      width: 100%;
    }
  }
}

//ぱんくず
.breadcrumb {
  position: absolute;
  top:5px;
  left:0;
  right:0;
  display: flex;
  width: 960px;
  margin: 0 auto;
  z-index: 1;
  @include sp {
    width: 100%;
    top:auto;
    bottom:0;
    overflow-x: scroll;
    white-space: nowrap;
    background-color: #fff;
    border-top:1px solid #f2f2f2;
    border-bottom:1px solid #f2f2f2;
    box-sizing: border-box;
  }
  &-white {
    .breadcrumb--link {
      color: #fff;
      &:after {
        border-top-color: #fff;
        border-right-color: #fff;
      }
    }
    .breadcrumb--item {
      color: #fff;
    }
    @include sp {
      .breadcrumb--link {
        color: #666;
        &:after {
          border-top-color: #666;
          border-right-color: #666;
        }
      }
      .breadcrumb--item {
        color: #666;
      }
    }
  }
  &-brown {
    .breadcrumb--link {
      color: #593A0E;
      &:after {
        border-top-color: #593A0E;
        border-right-color: #593A0E;
      }
    }
    .breadcrumb--item {
      color: #593A0E;
    }
    @include sp {
      .breadcrumb--link {
        color: #666;
        &:after {
          border-top-color: #666;
          border-right-color: #666;
        }
      }
      .breadcrumb--item {
        color: #666;
      }
    }
  }
  &--inner {
    @include sp {
      display: inline-block;
      padding: 10px;
    }
  }
  &--link {
    position: relative;
    display: inline-block;
    color: #666;
    @include fz(12);
    text-decoration: none;
    padding: 0 15px 0 0;
    &:after {
      content: "";
      position: absolute;
      top:0;
      bottom: 0;
      margin: auto 0;
      right: 5px;
      width: 5px;
      height: 5px;
      border-top: 1px solid #666;
      border-right: 1px solid #666;
      transform: rotate(45deg);
    }
  }
  &--item {
    position: relative;
    display: inline-block;
    color: #666;
    @include fz(12);
  }
}





/* 病院食「献立づくりのコツとポイント」「疾病別展開食のポイント」shadowbox ---------------------------------------------------- */

#sb-title-inner,#sb-info-inner,#sb-loading-inner,div.sb-message{font-family:"HelveticaNeue-Light","Helvetica Neue",Helvetica,Arial,sans-serif;font-weight:200;color:#fff;}
#sb-container{position:fixed;margin:0;padding:0;top:0;left:0;z-index:999;text-align:left;visibility:hidden;display:none;}
#sb-overlay{position:relative;height:100%;width:100%;}
#sb-wrapper{position:absolute;visibility:hidden;width:100px;}
#sb-wrapper-inner{position:relative;border:1px solid #ccc;}
#sb-body{position:relative;height:100%;}
#sb-body-inner{position:absolute;height:100%;width:100%;}
#sb-player.html{height:100%;overflow:hidden;}
#sb-body img{border:none;}
#sb-loading{position:relative;height:100%;}
#sb-loading-inner{position:absolute;font-size:12px;line-height:32px;height:32px;top:50%;margin-top:-12px;width:100%;text-align:center;}
#sb-loading-inner span{background:url(/hospital-food/js/shadowbox/loading.gif) no-repeat;padding-left:32px;display:inline-block;}
#sb-body,#sb-loading{background:#fff;}
#sb-title,#sb-info{position:relative;margin:0;padding:0;}
#sb-title,#sb-title-inner{}
#sb-title-inner{font-size:12px;}
#sb-info,#sb-info-inner{width:100%;}
#sb-info-inner{}
#sb-nav{position:relative;width:100%;}
#sb-nav a{display:block;cursor:pointer;background:no-repeat;}

#sb-nav-close{
	background:url(/hospital-food/js/shadowbox/btn_close.png) !important;
	width:71px;
	height:27px;
	margin:0 auto;
}
#sb-nav-close:hover {background:url(/hospital-food/js/shadowbox/btn_close-on.png) !important;}

#sb-nav-next{}
#sb-nav-previous{}
#sb-nav-play{}
#sb-nav-pause{}

#sb-counter{display:none;}
#sb-counter a{}
#sb-counter a.sb-counter-current{}
div.sb-message{display:none;}
div.sb-message a:link,div.sb-message a:visited{display:none;}