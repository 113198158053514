@charset "UTF-8";
.cmn_header {
  position: relative;
  border-top: 5px solid #42210B;
  height: 160px;
  box-sizing: border-box;
  box-shadow: 2px 0 5px 0 rgba(0, 0, 0, 0.3);
  z-index: 40;
  /*
  &--counter_link {
    position: relative;
    display: block;
    text-decoration: none;
    color: $colorSidenav;
    &:before {
      content: "";
      position: absolute;
      top:0;
      bottom: 0;
      margin: auto 0;
      left: -22px;
      width: 16px;
      height: 22px;
      border-radius: 5px;
      background-color: transparent;
      transition: .15s;
    }
    &:after {
      content: "";
      position: absolute;
      top:0;
      bottom: 0;
      margin: auto 0;
      left: -20px;
      width: 6px;
      height: 6px;
      border-top: 2px solid $colorSidenav;
      border-right: 2px solid $colorSidenav;
      transform: rotate(45deg);
    }
    &:hover {
      &:before {
        background-color: $colorYellow;
      }
    }
    @include sp {
      &:after {
        left: -10px;
      }
    }
  }
  &--counter_num {
    display: inline-block;
    @include fz(34);
    font-weight: 700;
  }
  &--counter_unit {
    display: inline-block;
  }
  */
}

@media (max-width: 425px) {
  .cmn_header {
    border-top-width: 3px;
    height: auto;
  }
}

.cmn_header--inner {
  position: relative;
  display: block;
  max-width: 960px;
  width: 100%;
  margin: 0 auto;
}

@media (max-width: 425px) {
  .cmn_header--name {
    border-bottom: 1px solid #f2f2f2;
  }
}

.cmn_header--logo_kp {
  display: block;
  width: 225px;
  padding: 15px 0 0 0;
}

@media (max-width: 425px) {
  .cmn_header--logo_kp {
    width: 160px;
    padding: 5px 0 5px 20px;
  }
}

.cmn_header--counter {
  position: absolute;
  top: 20px;
  left: 270px;
  z-index: 10;
}

@media (max-width: 425px) {
  .cmn_header--counter {
    top: 65px;
    left: 20px;
  }
}

.cmn_header--counter_ttl {
  font-size: 13px;
  font-size: 0.8125rem;
  margin: 0 0 5px 0;
}

@media (max-width: 425px) {
  .cmn_header--counter_ttl {
    font-size: 12px;
    font-size: 0.75rem;
  }
}

.cmn_header_nav {
  position: absolute;
  top: 10px;
  right: 0;
  display: flex;
}

@media (max-width: 425px) {
  .cmn_header_nav {
    display: none;
  }
}

.cmn_header_nav--link {
  position: relative;
  display: inline-block;
  color: #7b7b7b;
  font-size: 12px;
  font-size: 0.75rem;
  text-decoration: none;
  padding: 0 15px 0 5px;
  transition: .15s;
}

.cmn_header_nav--link:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  left: -5px;
  width: 4px;
  height: 4px;
  border-top: 1px solid #7b7b7b;
  border-right: 1px solid #7b7b7b;
  transform: rotate(45deg);
}

.cmn_header_nav--link:last-child {
  padding-right: 0;
}

.cmn_header_nav:hover {
  color: #000;
}

.cmn_header_user {
  position: absolute;
  top: 40px;
  right: 0;
  display: flex;
  align-items: center;
}

@media (max-width: 425px) {
  .cmn_header_user {
    top: 5px;
    right: 10px;
  }
}

.cmn_header_user--box {
  color: #646464;
}

@media (max-width: 425px) {
  .cmn_header_user--box {
    position: absolute;
    top: 0;
    right: 0;
    white-space: nowrap;
  }
}

.cmn_header_user--box-login {
  margin-right: 10px;
}

@media (max-width: 425px) {
  .cmn_header_user--box-login {
    width: auto;
    margin-right: 0;
  }
}

.cmn_header_user--name {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.2;
  font-weight: 700;
}

@media (max-width: 425px) {
  .cmn_header_user--name {
    font-size: 12px;
    font-size: 0.75rem;
  }
}

.cmn_header_user--dear {
  font-size: 16px;
  font-size: 1rem;
  padding: 0 0 0 5px;
}

@media (max-width: 425px) {
  .cmn_header_user--dear {
    font-size: 12px;
    font-size: 0.75rem;
  }
}

.cmn_header_user--config {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 30px;
  color: #646464;
  font-size: 16px;
  font-size: 1rem;
  text-decoration: none;
}

.cmn_header_user--config:before {
  font-family: "icomoon" !important;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "ꤐ";
  font-size: 22px;
  font-size: 1.375rem;
  margin-right: 10px;
}

@media (max-width: 425px) {
  .cmn_header_user--config {
    font-size: 12px;
    font-size: 0.75rem;
    margin: 20px 10px 10px 0;
    padding-left: 0;
  }
  .cmn_header_user--config:before {
    position: relative;
    vertical-align: middle;
    font-size: 16px;
    font-size: 1rem;
    margin-right: 5px;
  }
}

.cmn_header_user--btn {
  position: relative;
  width: 155px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-size: 0.875rem;
  text-decoration: none;
  transition: .15s;
}

.cmn_header_user--btn:before {
  font-family: "icomoon" !important;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
  font-size: 1.125rem;
  margin: 0 10px 0 0;
}

@media (max-width: 425px) {
  .cmn_header_user--btn {
    height: 26px;
    font-size: 12px;
    font-size: 0.75rem;
    margin: 10px auto;
  }
  .cmn_header_user--btn:before {
    font-size: 14px;
    font-size: 0.875rem;
    margin-right: 5px;
  }
}

.cmn_header_user--btn-login {
  background-color: #8b7d75;
  border-radius: 5px;
  color: #fff;
}

.cmn_header_user--btn-login:before {
  content: "ꤠ";
}

.cmn_header_user--btn-login:hover {
  background-color: #6e5d53;
}

@media (max-width: 425px) {
  .cmn_header_user--btn-login {
    width: 102px;
    margin-top: 20px;
  }
}

.cmn_header_user--btn-logout {
  border: 1px solid #d5d5d5;
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 5px;
  color: #646464;
}

.cmn_header_user--btn-logout:before {
  content: "ꤑ";
}

.cmn_header_user--btn-logout:hover {
  background-color: #e6e6e6;
}

@media (max-width: 425px) {
  .cmn_header_user--btn-logout {
    border: none;
    width: 95px;
    border-radius: 0;
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
    margin: 20px auto 10px auto;
  }
}

.cmn_header_search {
  border-top: 1px solid #f2f2f2;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (max-width: 425px) {
  .cmn_header_search {
    position: relative;
  }
}

.cmn_header_search--inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 960px;
  height: 58px;
  margin: 0 auto;
  border-bottom: 2px solid #ff8123;
}

@media (max-width: 425px) {
  .cmn_header_search--inner {
    justify-content: flex-end;
    width: 100%;
    height: 56px;
    border-bottom: 1px solid #f2f2f2;
  }
}

.cmn_header_search--link {
  position: relative;
  display: flex;
  align-items: center;
  width: 200px;
  height: 58px;
  padding-left: 30px;
  box-sizing: border-box;
  font-size: 14px;
  font-size: 0.875rem;
  text-decoration: none;
  color: #fff;
  background-color: #ff8123;
  background-image: url("/container/common/images/cmn-img-cooker.png");
  background-position: 120px 6px;
  background-repeat: no-repeat;
  transition: .15s;
}

.cmn_header_search--link:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 20px;
  margin: auto 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 0 6px 6px;
  border-color: transparent transparent transparent #fff;
}

.cmn_header_search--link:hover {
  background-color: #ffcc00;
  color: #603813;
}

.cmn_header_search--link:hover:before {
  border-color: transparent transparent transparent #603813;
}

@media (max-width: 425px) {
  .cmn_header_search--link {
    display: none;
  }
}

.cmn_header_search--type {
  display: flex;
  align-items: center;
}

@media (max-width: 425px) {
  .cmn_header_search--type {
    display: none;
  }
}

.cmn_header_search--type_txt {
  font-size: 14px;
  font-size: 0.875rem;
  display: inline-block;
  margin: 0 10px 0 0;
}

.cmn_header_search--select {
  padding: 0 15px;
  position: relative;
  border: 1px solid #ccc;
  border-radius: 5px;
  min-width: 170px;
  overflow: hidden;
  box-sizing: border-box;
  height: 36px;
}

.cmn_header_search--select:after {
  position: absolute;
  top: calc(50% - 3px);
  right: 15px;
  content: "";
  display: block;
  border-style: solid;
  border-width: 6px 6px 0 6px;
  border-color: #646464 transparent transparent transparent;
}

.cmn_header_search--select_tag {
  font-size: 14px;
  font-size: 0.875rem;
  position: relative;
  background-color: transparent;
  height: 36px;
  -webkit-appearance: none;
  width: 120%;
  left: -5%;
  z-index: 1;
  color: #949494;
  border: 0;
}

.cmn_header_search--label {
  font-size: 14px;
  font-size: 0.875rem;
  margin: 0 10px 0 0;
  color: #603813;
}

.cmn_header_search--link_btn {
  font-size: 14px;
  font-size: 0.875rem;
  position: relative;
  display: flex;
  align-items: center;
  width: 170px;
  height: 58px;
  padding: 0 0 0 30px;
  color: #603813;
  text-decoration: none;
  box-sizing: border-box;
  border-right: 1px solid #f2f2f2;
  border-left: 1px solid #f2f2f2;
  box-sizing: border-box;
}

.cmn_header_search--link_btn:after {
  position: absolute;
  top: 5px;
  left: 5px;
  opacity: 0;
  background-color: #c89b05;
  content: "";
  display: block;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  border-radius: 5px;
  transform: scale(0.9, 0.9);
  transition: .15s;
}

.cmn_header_search--link_btn:hover:after {
  opacity: .3;
  transform: scale(1, 1);
}

@media (max-width: 425px) {
  .cmn_header_search--link_btn {
    display: none;
  }
}

.cmn_header_search--link_btn_cross {
  display: inline-block;
  position: absolute;
  top: calc(50% - 8px);
  right: 35px;
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background-color: #ff8123;
}

.cmn_header_search--link_btn_cross:before, .cmn_header_search--link_btn_cross:after {
  position: absolute;
  top: 7px;
  left: 3px;
  content: "";
  display: block;
  width: 10px;
  height: 2px;
  background: #fff;
}

.cmn_header_search--link_btn_cross:after {
  transform: rotate(-90deg);
}

.cmn_header_search--link_btn_cross.active:before {
  display: none;
}

.cmn_header_search--link_btn_cross.active:after {
  transform: rotate(0deg);
}

.gl_search_detail {
  opacity: 0;
  visibility: hidden;
  transition: .3s;
  position: absolute;
  left: 0;
  right: 0;
  top: 60px;
  width: 960px;
  margin: 0 auto;
}

.gl_search_detail.active {
  opacity: 1;
  visibility: visible;
  z-index: 130;
  top: 70px;
}

.gl_search_detail--bg {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  transition: opacity .3s;
  z-index: 1;
  top: 60px;
  left: 0;
  width: 100%;
  background-image: url("/container/common/images/bg-main-content.png");
}

.gl_search_detail--bg.active {
  opacity: 1;
  visibility: visible;
  z-index: 9;
}

.gl_search_detail--in {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
}

.gl_search_detail--intro {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.4;
  color: #603813;
  padding: 20px 0;
}

.gl_search_detail--row {
  width: 895px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  border-bottom: 1px solid #efebe8;
  padding: 5px 0 10px 0;
}

.gl_search_detail--l {
  font-weight: 700;
  color: #42210B;
  width: 180px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 15px;
  font-size: 0.9375rem;
}

.gl_search_detail--r {
  width: 715px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 14px;
  font-size: 0.875rem;
}

.gl_search_detail--label {
  margin: 0 15px 5px 0;
  padding: 0;
  color: #505050;
  cursor: pointer;
  transition: .15s;
}

.gl_search_detail--label:hover {
  color: #cca300;
}

.gl_search_detail--radiobox {
  position: relative;
  top: 5px;
  border: 1px solid #505050;
  appearance: none;
  cursor: pointer;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  transition: .15s;
}

.gl_search_detail--radiobox:after {
  position: absolute;
  top: 2px;
  left: 2px;
  background-color: #cca300;
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transform: scale(0, 0);
}

.gl_search_detail--radiobox:checked {
  border-radius: 50%;
}

.gl_search_detail--radiobox:checked:after {
  animation: inputFade 0.2s cubic-bezier(0.895, 0.03, 0.685, 0.22) forwards;
}

@keyframes inputFade {
  0% {
    transform: scale(0, 0);
  }
  100% {
    transform: scale(1, 1);
  }
}

.gl_search_detail--checkbox {
  position: relative;
  top: 5px;
  border: 1px solid #505050;
  appearance: none;
  cursor: pointer;
  width: 16px;
  height: 16px;
  transition: .15s;
}

.gl_search_detail--checkbox:after {
  position: absolute;
  top: 2px;
  left: 2px;
  background-color: #cca300;
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  transform: scale(0, 0);
}

.gl_search_detail--checkbox:checked:after {
  animation: inputFade 0.2s cubic-bezier(0.895, 0.03, 0.685, 0.22) forwards;
}

@keyframes inputFade {
  0% {
    transform: scale(0, 0);
  }
  100% {
    transform: scale(1, 1);
  }
}

.gl_search_detail--select_box {
  margin: 5px 3px 0 0;
  position: relative;
  display: inline-block;
  top: 0;
  background-color: #eeeae6;
  height: 34px;
  width: 190px;
  overflow: hidden;
}

.gl_search_detail--select_box:before {
  position: absolute;
  top: 8px;
  right: 10px;
  content: "";
  display: block;
  border-style: solid;
  border-width: 0 6px 6px 6px;
  border-color: transparent transparent #725c50 transparent;
}

.gl_search_detail--select_box:after {
  position: absolute;
  right: 10px;
  bottom: 8px;
  content: "";
  display: block;
  border-style: solid;
  border-width: 6px 6px 0 6px;
  border-color: #725c50 transparent transparent transparent;
}

.gl_search_detail--select_box:nth-child(3n) {
  margin-right: 0;
}

.gl_search_detail--select_box-wide {
  width: 290px;
}

.gl_search_detail--select {
  font-size: 14px;
  font-size: 0.875rem;
  padding: 5px 10px;
  background-color: transparent;
  appearance: none;
  width: 290px;
  box-sizing: border-box;
  border: 0;
  height: 34px;
  overflow: hidden;
}

.gl_search_detail--select-wide {
  width: 390px;
}

.gl_search_detail--input_box {
  display: inline-block;
}

.gl_search_detail--input {
  display: inline-block;
  font-size: 14px;
  font-size: 0.875rem;
  margin: 5px 10px 0 0;
  padding: 5px 10px;
  background-color: #eeeae6;
  width: 190px;
  border: 0;
  height: 34px;
  box-sizing: border-box;
  z-index: 100;
  position: relative;
}

.gl_search_detail--type {
  display: inline-block;
  font-size: 18px;
  font-size: 1.125rem;
  position: relative;
  top: 5px;
  font-weight: 700;
}

.gl_search_detail--txt {
  display: inline;
  margin: 0 0 0 10px;
  position: relative;
  top: 4px;
  line-height: 1.3;
}

.gl_search_detail--submit_box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
  background-color: #ffcc00;
  width: 400px;
  height: 60px;
  border-radius: 30px;
  transition: .15s;
}

.gl_search_detail--submit_box:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 30px;
  width: 7px;
  height: 7px;
  margin: auto 0;
  border-top: 2px solid #603813;
  border-right: 2px solid #603813;
  transform: rotate(45deg);
  transition: .15s;
}

.gl_search_detail--submit_box:hover {
  filter: contrast(120%);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.3);
}

.gl_search_detail--submit {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 700;
  background-color: transparent;
  width: 100%;
  border: 0;
  color: #603813;
  appearance: none;
  height: 60px;
  cursor: pointer;
  transition: .15s;
}

@media (max-width: 425px) {
  .gl_footer {
    margin-bottom: 60px;
  }
}

.gl_footer--top_btn {
  position: fixed;
  right: 25px;
  bottom: 25px;
  background-color: rgba(62, 37, 22, 0.6);
  width: 70px;
  height: 70px;
  text-decoration: none;
  transition: .15s;
  border-radius: 50%;
  color: #fff;
  z-index: 120;
}

.gl_footer--top_btn:before {
  content: "";
  position: absolute;
  top: calc(50% - 6px);
  left: 0;
  right: 0;
  width: 16px;
  height: 16px;
  border-top: 5px solid #fff;
  border-right: 5px solid #fff;
  transform: rotate(-45deg);
  margin: 0 auto;
  display: block;
}

.gl_footer--top_btn:hover {
  background: rgba(62, 37, 22, 0.9);
}

@media (max-width: 425px) {
  .gl_footer--top_btn {
    right: 0;
    bottom: 0;
    width: 70px;
    height: 60px;
    border-radius: 0;
    background-color: #42210B;
  }
  .gl_footer--top_btn:before {
    width: 10px;
    height: 10px;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    top: 20px;
  }
  .gl_footer--top_btn:after {
    content: "Page Top";
    font-size: 10px;
    font-size: 0.625rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 0 0 10px 0;
    height: 100%;
    box-sizing: border-box;
  }
}

.gl_footer--top {
  background-image: url("/container/common/images/bg-main-content-footer.png");
}

.gl_footer--bottom {
  padding: 25px 0 15px;
  border-bottom: 1px solid #ccc;
  background: #fff;
}

@media (max-width: 425px) {
  .gl_footer--bottom {
    padding: 0;
  }
}

.gl_footer--in {
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

@media (max-width: 425px) {
  .gl_footer--in {
    padding: 20px 10px;
    justify-content: space-between;
  }
}

.gl_footer--request_btn {
  position: relative;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  text-align: center;
  color: #fff;
  text-decoration: none;
  width: 170px;
  height: 90px;
  padding: 13px 0 0 0;
  box-sizing: border-box;
  background-color: #ff8123;
  box-shadow: 0 2px 0 #b55e33;
  border-radius: 5px;
  transition: .15s;
}

.gl_footer--request_btn:before {
  font-family: "icomoon" !important;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;
  font-size: 1.25rem;
  margin: 10px 0;
  content: "꤈";
  display: block;
}

.gl_footer--request_btn:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  transition: .15s;
  width: 100%;
  border-radius: 5px;
  height: 100%;
  box-shadow: 0 0 0 #b55e33 inset;
}

.gl_footer--request_btn:hover {
  top: 2px;
  background-color: #f57927;
  box-shadow: 0 0 0 #b55e33;
}

.gl_footer--request_btn:hover:after {
  box-shadow: 0 2px 0 #b55e33 inset;
}

@media (max-width: 425px) {
  .gl_footer--request_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0;
    padding-left: 30px;
    width: 100%;
    height: 50px;
    margin: 0 auto 20px auto;
  }
  .gl_footer--request_btn:before {
    margin-right: 10px;
    margin-left: -40px;
  }
}

.gl_footer--copy_text {
  font-size: 12px;
  font-size: 0.75rem;
  margin: 0 0 0 auto;
  text-align: right;
  display: inline-block;
}

@media (max-width: 425px) {
  .gl_footer--copy_text {
    margin: 0;
  }
}

.gl_footer--logo {
  font-size: 18px;
  font-size: 1.125rem;
  margin: 0 0 0 20px;
  text-decoration: none;
  color: #505050;
  transition: .15s;
}

.gl_footer--logo:before {
  font-family: "icomoon" !important;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "ꤘ";
}

.gl_footer--logo:hover {
  color: #ffcc00;
}

.gl_footer_nav {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 195px);
  margin: 0 0 0 25px;
}

@media (max-width: 425px) {
  .gl_footer_nav {
    width: 100%;
    margin-left: 0;
  }
}

.gl_footer_nav--link_btn {
  position: relative;
  display: block;
  padding: 33px 0 0 0;
  text-align: center;
  height: 150px;
  width: 25%;
  box-sizing: border-box;
  text-decoration: none;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  transition: .15s;
}

.gl_footer_nav--link_btn:last-child {
  border-right: 1px solid rgba(255, 255, 255, 0.3);
}

.gl_footer_nav--link_btn:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: block;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background-color: #eadccb;
  z-index: 1;
}

.gl_footer_nav--link_btn:before {
  position: relative;
  font-family: "icomoon" !important;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 30px;
  font-size: 1.875rem;
  margin: 0 0 30px 0;
  display: block;
  transition: .15s;
  color: #6d3315;
  width: 100%;
  z-index: 3;
}

.gl_footer_nav--link_btn:hover {
  background-color: rgba(0, 0, 0, 0.15);
}

.gl_footer_nav--link_btn:hover .gl_footer_nav--ttl {
  color: #fff;
}

@media (max-width: 425px) {
  .gl_footer_nav--link_btn {
    width: 50%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }
  .gl_footer_nav--link_btn:last-child {
    border-right: none;
  }
  .gl_footer_nav--link_btn:nth-child(2n-1) {
    border-left: none;
  }
  .gl_footer_nav--link_btn:nth-child(3), .gl_footer_nav--link_btn:nth-child(4) {
    border-bottom: none;
  }
}

.gl_footer_nav--link_btn-catalog:before {
  content: "꤂";
}

.gl_footer_nav--link_btn-experience:before {
  content: "ꤖ";
}

.gl_footer_nav--link_btn-faq:before {
  content: "꤃";
}

.gl_footer_nav--link_btn-contact:before {
  content: "꤀";
}

.gl_footer_nav--ttl {
  margin: 0 0 10px 0;
  font-size: 16px;
  font-size: 1rem;
  color: #eadccb;
  display: block;
  width: 100%;
  transition: .15s;
}

@media (max-width: 425px) {
  .gl_footer_nav--ttl {
    font-size: 14px;
    font-size: 0.875rem;
  }
}

.gl_footer_nav--catch {
  font-size: 14px;
  font-size: 0.875rem;
  color: #e8e3df;
  width: 100%;
  display: block;
}

.gl_footer_nav--catch:before {
  margin: 0 5px 0 0;
  position: relative;
  top: 1px;
  content: "";
  display: inline-block;
  border-style: solid;
  border-width: 6px 0 6px 6px;
  border-color: transparent transparent transparent #e8e3df;
}

@media (max-width: 425px) {
  .gl_footer_nav--catch {
    font-size: 12px;
    font-size: 0.75rem;
  }
}

.gl_footer_bottom_nav {
  padding: 15px 0;
  background: #fff;
}

.gl_footer_bottom_nav--in {
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

@media (max-width: 425px) {
  .gl_footer_bottom_nav--in {
    padding-right: 15px;
  }
}

.gl_footer_bottom_nav--link_btn {
  font-size: 12px;
  font-size: 0.75rem;
  padding: 0 15px;
  color: #000;
  display: block;
  text-decoration: none;
  border-right: 1px solid #000;
  transition: .15s;
}

.gl_footer_bottom_nav--link_btn:first-child {
  border-left: 1px solid #000;
}

.gl_footer_bottom_nav--link_btn:hover {
  color: #ffcc00;
}

.gnav {
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 130px;
  height: 100%;
  z-index: 110;
}

@media (max-width: 1279px) {
  .gnav {
    pointer-events: none;
  }
}

@media (max-width: 425px) {
  .gnav {
    display: none;
  }
}

.gnav--bg {
  position: relative;
  display: block;
  background-image: url("/container/common/images/cmn-sidemenu-bg.png");
  box-shadow: -1px -1px 3px 1px rgba(0, 0, 0, 0.3) inset;
  width: 130px;
  height: calc(100% + 1px);
  z-index: 5;
}

@media (max-width: 1279px) {
  .gnav--bg {
    width: 15px;
    transition: .15s;
  }
  .gnav--bg.slide {
    width: 130px;
  }
  .gnav--bg.slide .gnav--inner {
    opacity: 1;
    pointer-events: auto;
  }
}

.gnav--inner {
  display: block;
}

@media (max-width: 1279px) {
  .gnav--inner {
    position: relative;
    opacity: 0;
    pointer-events: none;
    transition: .3s;
    z-index: 100;
  }
}

@media (max-width: 425px) {
  .gnav--inner {
    display: none;
  }
}

.gnav--tab {
  display: none;
}

@media (max-width: 1279px) {
  .gnav--tab {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 10px;
    margin: auto 0;
    width: 30px;
    height: 60px;
    border-radius: 0 30px 30px 0;
    background-image: url("/container/common/images/cmn-sidemenu-bg.png");
    box-shadow: 3px 0 1px 1px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    pointer-events: auto;
    transition: .15s;
    z-index: 6;
  }
  .gnav--tab:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 5px;
    margin: auto 0;
    width: 10px;
    height: 10px;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(45deg);
  }
  .gnav--tab.slide {
    left: calc(130px - 5px);
  }
  .gnav--tab.slide:before {
    transform: rotate(-135deg);
  }
  .gnav--tab.hidden {
    opacity: 0;
    pointer-events: none;
  }
}

@media (max-width: 425px) {
  .gnav--tab {
    display: none;
  }
}

.gnav--close {
  display: none;
  position: absolute;
  top: 0;
  right: -36px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  background-color: #42210B;
  z-index: 10;
}

.gnav--close:before, .gnav--close:after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 86%;
  height: 4%;
  margin: -2% 0 0 -43%;
  background-color: #fff;
}

.gnav--close:before {
  transform: rotate(-45deg);
}

.gnav--close:after {
  transform: rotate(45deg);
}

.gnav--close-on {
  display: block;
}

.gnav--link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.3;
  font-weight: 700;
  text-align: center;
  color: #42210B;
  text-decoration: none;
  padding: 7px 0;
  box-sizing: border-box;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  transition: .15s;
}

.gnav--link:before {
  position: relative;
  font-family: "icomoon" !important;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-bottom: 5px;
}

.gnav--link:hover {
  color: #f16b24;
}

.gnav--link.active {
  background-color: #42210B;
  color: #f16b24;
}

.gnav--link-home {
  padding-top: 10px;
}

.gnav--link-home:before {
  content: "ꤒ";
  font-size: 22px;
  font-size: 1.375rem;
}

.gnav--link-navi:before {
  content: "꤇";
  font-size: 22px;
  font-size: 1.375rem;
}

.gnav--link-tech:before {
  content: "ꤕ";
  font-size: 25px;
  font-size: 1.5625rem;
}

.gnav--link-case:before {
  content: "ꤗ";
  font-size: 25px;
  font-size: 1.5625rem;
}

.gnav--link-labo:before {
  content: "ꤔ";
  font-size: 27px;
  font-size: 1.6875rem;
}

.gnav--link-hospitalfood:before {
  content: "ꤓ";
  font-size: 32px;
  font-size: 2rem;
}

.gnav--link-testkitchen:before {
  content: "ꤖ";
  font-size: 21px;
  font-size: 1.3125rem;
}

.gnav--link-mini {
  padding-top: 10px;
}

.gnav_box_2nd {
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 130px;
  width: 0;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  background-color: #42210B;
  color: #ffcc00;
  transition-duration: .3s;
  z-index: 10;
}

.gnav_box_2nd.open {
  opacity: 1;
  pointer-events: auto;
  width: 260px;
}

.gnav_box_2nd.open .gnav_box_2nd--wrap {
  opacity: 1;
}

.gnav_box_2nd--wrap {
  opacity: 0;
  transition-duration: .15s;
  transition-delay: .3s;
}

.gnav_box_2nd--category {
  position: relative;
  display: block;
  color: #ffcc00;
  text-decoration: none;
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1.3;
  font-weight: bold;
  margin: 10px 0;
  padding: 15px 0;
  transition: .15s;
}

.gnav_box_2nd--category:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 10px;
  width: 5px;
  height: 5px;
  border-top: 2px solid #ffcc00;
  border-right: 2px solid #ffcc00;
  transform: rotate(45deg);
}

.gnav_box_2nd--category:hover {
  background-color: #ffcc00;
  border-radius: 5px;
  color: #42210B;
}

.gnav_box_2nd--category:hover:after {
  border-top-color: #42210B;
  border-right-color: #42210B;
}

.gnav_box_2nd--category:before {
  position: relative;
  font-family: "icomoon" !important;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 10px 0 20px;
  vertical-align: middle;
}

.gnav_box_2nd--category-navi:before {
  content: "꤇";
  font-size: 25px;
  font-size: 1.5625rem;
}

.gnav_box_2nd--category-tech:before {
  content: "ꤕ";
  font-size: 25px;
  font-size: 1.5625rem;
}

.gnav_box_2nd--category-case:before {
  content: "ꤗ";
  font-size: 25px;
  font-size: 1.5625rem;
}

.gnav_box_2nd--category-labo:before {
  content: "ꤔ";
  font-size: 28px;
  font-size: 1.75rem;
}

.gnav_box_2nd--category-hospitalfood:before {
  content: "ꤓ";
  font-size: 32px;
  font-size: 2rem;
  top: -3px;
}

.gnav_box_2nd--category-testkitchen:before {
  content: "ꤖ";
  font-size: 22px;
  font-size: 1.375rem;
  top: -3px;
}

.gnav_box_2nd--ttl {
  background-color: #603813;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  color: #fff;
  padding: 10px;
  margin-top: 15px;
  margin-bottom: 5px;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: normal;
}

.gnav_box_2nd--linkitem {
  display: block;
  border-bottom: 1px dotted #efebe8;
  text-decoration: none;
}

.gnav_box_2nd--linkitem.active .gnav_box_2nd--ttl_sub {
  background-color: #ffcc00;
  border-radius: 5px;
  color: #42210B;
}

.gnav_box_2nd--linkitem.active .gnav_box_2nd--ttl_sub:before {
  background-color: #603813;
}

.gnav_box_2nd--linkitem.active .gnav_box_2nd--ttl_sub:after {
  border-top-color: #603813;
  border-right-color: #603813;
  transform: rotate(-135deg);
  right: 12px;
}

.gnav_box_2nd--linkitem-topline {
  border-top: 1px dotted #efebe8;
}

.gnav_box_2nd--ttl_sub {
  position: relative;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.2;
  color: #fff;
  font-weight: normal;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  transition: .3s;
}

.gnav_box_2nd--ttl_sub:before, .gnav_box_2nd--ttl_sub:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 10px;
}

.gnav_box_2nd--ttl_sub:before {
  width: 10px;
  height: 1px;
  background-color: #fff;
}

.gnav_box_2nd--ttl_sub:after {
  width: 6px;
  height: 6px;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  transform: rotate(45deg);
}

.gnav_box_2nd--ttl_sub:hover {
  background-color: #ffcc00;
  border-radius: 5px;
  color: #42210B;
}

.gnav_box_2nd--ttl_sub:hover:before {
  background-color: #603813;
}

.gnav_box_2nd--ttl_sub:hover:after {
  border-top-color: #603813;
  border-right-color: #603813;
}

.gnav_box_2nd--link {
  position: relative;
  display: block;
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.3;
  padding: 5px 15px 5px 7px;
  transition: .15s;
}

.gnav_box_2nd--link:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 10px;
  width: 5px;
  height: 5px;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  transform: rotate(45deg);
}

.gnav_box_2nd--link:hover {
  color: #ffcc00;
}

.gnav_box_2nd--link:hover:after {
  border-top-color: #ffcc00;
  border-right-color: #ffcc00;
}

.gnav_box_3rd {
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: calc(130px + 260px);
  width: 0;
  height: 100%;
  padding: 30px 0 0 60px;
  box-sizing: border-box;
  background-color: #fff;
  color: #603813;
  transition-duration: .3s;
}

.gnav_box_3rd.open {
  opacity: 1;
  pointer-events: auto;
  width: calc(100% - (130px + 260px));
}

.gnav_box_3rd.open .gnav_box_3rd--wrap {
  opacity: 1;
}

@media (max-width: 1279px) {
  .gnav_box_3rd {
    overflow-y: scroll;
  }
}

.gnav_box_3rd--wrap {
  opacity: 0;
  transition-duration: .15s;
  transition-delay: .3s;
}

.gnav_box_3rd--category {
  color: #603813;
  font-weight: 700;
  margin: 0 0 40px 0;
}

.gnav_box_3rd--category_name1 {
  font-size: 13px;
  font-size: 0.8125rem;
  padding-bottom: 10px;
}

.gnav_box_3rd--category_name2 {
  font-size: 17px;
  font-size: 1.0625rem;
}

.gnav_box_3rd--inner {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 760px;
}

.gnav_box_3rd--innerbox {
  width: 32%;
  margin-right: 2%;
}

.gnav_box_3rd--innerbox:last-child {
  margin-right: 0;
}

@media (max-width: 1279px) {
  .gnav_box_3rd--innerbox {
    width: 46%;
    margin-right: 2%;
    margin-bottom: 20px;
  }
}

@media (max-width: 1279px) {
  .gnav_box_3rd--innerbox-forlaptop {
    width: 100%;
    display: flex;
  }
}

.gnav_box_3rd--innerbox_forlaptop {
  margin-bottom: 20px;
}

@media (max-width: 1279px) {
  .gnav_box_3rd--innerbox_forlaptop {
    width: 46%;
    margin-right: 2%;
  }
}

.gnav_box_3rd--ttl {
  display: flex;
  align-items: center;
  min-height: 40px;
  background-color: #ffcc00;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  color: #603813;
  padding: 0 10px;
  margin: 30px 0 10px 0;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.2;
  font-weight: 400;
  border-bottom: 1px solid #603813;
}

.gnav_box_3rd--ttl:first-of-type {
  margin-top: 0;
}

.gnav_box_3rd--ttl2 {
  color: #603813;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 700;
  margin-top: 25px;
  margin-bottom: 10px;
  padding: 0 0 0 5px;
}

.gnav_box_3rd--link {
  position: relative;
  display: block;
  color: #000;
  text-decoration: none;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.3;
  padding: 10px 5px;
  transition: .2s;
}

.gnav_box_3rd--link:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 5px;
  width: 5px;
  height: 5px;
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  transform: rotate(45deg);
}

.gnav_box_3rd--link:hover {
  color: #603813;
}

.gnav_box_3rd--link:hover:after {
  border-top-color: #603813;
  border-right-color: #603813;
}

.gnav_sp {
  display: none;
}

@media (max-width: 425px) {
  .gnav_sp {
    position: fixed;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 60px;
    background-image: url("/container/common/images/cmn-sidemenu-bg.png");
    box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.3) inset;
    z-index: 110;
  }
}

.gnav_sp--mainmenu {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 70px - 5px);
  height: 100%;
}

.gnav_sp--menubtn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-width: 60px;
  cursor: pointer;
  font-size: 10px;
  font-size: 0.625rem;
  line-height: 1.1;
  font-weight: 700;
  color: #603813;
}

.gnav_sp--menubtn_icon {
  position: relative;
  width: 17px;
  height: 17px;
  border-bottom: 2px solid #603813;
  box-sizing: border-box;
  margin: 5px 0;
}

.gnav_sp--menubtn_icon:before, .gnav_sp--menubtn_icon:after {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #603813;
}

.gnav_sp--menubtn_icon:before {
  top: 0;
}

.gnav_sp--menubtn_icon:after {
  top: calc(50% - 1px);
}

.gnav_sp--mainlink {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: calc(100% / 4);
  min-width: 60px;
  font-size: 10px;
  font-size: 0.625rem;
  line-height: 1.1;
  font-weight: 700;
  text-decoration: none;
  color: #603813;
  text-align: center;
}

.gnav_sp--mainlink:before {
  position: relative;
  font-family: "icomoon" !important;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-bottom: 5px;
}

.gnav_sp--mainlink-home:before {
  content: "ꤒ";
  font-size: 22px;
  font-size: 1.375rem;
}

.gnav_sp--mainlink-beginner:before {
  content: "꤇";
  font-size: 22px;
  font-size: 1.375rem;
}

.gnav_sp--mainlink-search:before {
  content: "ꤙ";
  font-size: 22px;
  font-size: 1.375rem;
}

.gnav_sp_contents {
  display: none;
}

@media (max-width: 425px) {
  .gnav_sp_contents {
    display: block;
    height: 0;
    opacity: 0;
    pointer-events: none;
    overflow: hidden;
    z-index: -1;
    transition: .3s;
  }
  .gnav_sp_contents.open {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 1;
    pointer-events: auto;
    overflow-y: scroll;
    z-index: 150;
  }
  .gnav_sp_contents--inenr {
    position: relative;
    margin-top: 50px;
  }
  .gnav_sp_contents--bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background-color: #42210B;
  }
  .gnav_sp_contents--close {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 80px;
    height: 100%;
    cursor: pointer;
    font-size: 10px;
    font-size: 0.625rem;
    line-height: 1.1;
    color: #fff;
  }
  .gnav_sp_contents--close_icon {
    position: relative;
    width: 20px;
    height: 20px;
    margin: 2px 0;
  }
  .gnav_sp_contents--close_icon:before {
    display: inline-block;
    content: "";
    position: absolute;
    top: 0;
    left: 9px;
    width: 2px;
    height: 20px;
    background-color: #fff;
    transform: rotate(45deg);
  }
  .gnav_sp_contents--close_icon:after {
    display: inline-block;
    content: "";
    position: absolute;
    top: 0;
    right: 9px;
    width: 2px;
    height: 20px;
    border-radius: 1px;
    background-color: #fff;
    transform: rotate(-45deg);
  }
  .gnav_sp_contents--ttl {
    background-image: url("/container/common/images/bg-main-content-footer.png");
    padding: 15px 0;
    text-align: center;
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.2;
    font-weight: 400;
    color: #fff;
  }
}

@media (max-width: 425px) {
  .gnav_sp_search {
    position: relative;
    background-color: #fff;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 56px;
    padding: 10px 0;
    margin: 50px 0 0 0;
    box-sizing: border-box;
  }
  .gnav_sp_search--counter {
    position: absolute;
    top: auto;
    bottom: auto;
    left: 20px;
    margin: auto 0;
  }
  .gnav_sp_submenu {
    position: relative;
    padding: 15px 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-image: url("/container/common/images/cmn-sidemenu-bg.png");
  }
  .gnav_sp_submenu--item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(50% - 20px);
    height: 100px;
    box-sizing: border-box;
  }
  .gnav_sp_submenu--link {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #42210B;
    text-decoration: none;
    text-align: center;
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 1.2;
    font-weight: 700;
    padding: 10px;
  }
  .gnav_sp_submenu--link:before {
    position: relative;
    font-family: "icomoon" !important;
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    speak: none;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding-bottom: 10px;
  }
  .gnav_sp_submenu--link-navi:before {
    content: "꤇";
    font-size: 30px;
    font-size: 1.875rem;
  }
  .gnav_sp_submenu--link-tech:before {
    content: "ꤕ";
    font-size: 32px;
    font-size: 2rem;
  }
  .gnav_sp_submenu--link-case:before {
    content: "ꤗ";
    font-size: 28px;
    font-size: 1.75rem;
  }
  .gnav_sp_submenu--link-labo:before {
    content: "ꤔ";
    font-size: 32px;
    font-size: 2rem;
  }
  .gnav_sp_submenu--link-hospitalfood:before {
    content: "ꤓ";
    font-size: 34px;
    font-size: 2.125rem;
  }
  .gnav_sp_submenu--link-testkitchen:before {
    content: "ꤖ";
    font-size: 25px;
    font-size: 1.5625rem;
  }
  .gnav_sp_footer {
    background-image: url("/container/common/images/bg-main-content-footer.png");
    padding: 20px 0;
  }
  .gnav_sp_footer--bnrlink {
    position: relative;
    display: block;
    width: 320px;
    margin: 0 auto;
  }
  .gnav_sp_footer--bnrimg {
    width: 100%;
  }
  .gnav_sp_footer--inner {
    width: 320px;
    margin: 0 auto;
    padding: 20px 0;
    display: flex;
    flex-wrap: wrap;
  }
  .gnav_sp_footer--link {
    position: relative;
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.2;
    color: #fff;
    text-decoration: none;
    padding: 10px 0 10px 10px;
    box-sizing: border-box;
  }
  .gnav_sp_footer--link:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 0 4px 4px;
    border-color: transparent transparent transparent #fff;
  }
  .gnav_sp_footer--link:nth-child(2n-1) {
    width: 55%;
  }
  .gnav_sp_footer--link:nth-child(2n) {
    width: 45%;
  }
  .gnav_search_detail {
    position: relative;
    margin-bottom: 90px;
  }
  .gnav_search_detail--ttl {
    position: relative;
    color: #42210B;
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 700;
    padding: 12px 15px;
    background-image: url("/container/common/images/bg-main-content.png");
    border-bottom: 1px solid #fff;
    transition: .15s;
  }
  .gnav_search_detail--ttl.active:after {
    transform: rotate(-45deg);
  }
  .gnav_search_detail--ttl-toggle:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 20px;
    margin: auto 0;
    width: 7px;
    height: 7px;
    border-top: 2px solid #42210B;
    border-right: 2px solid #42210B;
    transform: rotate(135deg);
    transition: .15s;
  }
  .gnav_search_detail--box {
    position: relative;
    box-sizing: border-box;
    opacity: 0;
    pointer-events: none;
    height: 0;
    transition: .3s;
  }
  .gnav_search_detail--box.open {
    opacity: 1;
    pointer-events: auto;
    height: auto;
  }
  .gnav_search_detail--inner {
    padding: 10px 15px 0 15px;
  }
  .gnav_search_detail--package {
    display: flex;
    flex-wrap: wrap;
  }
  .gnav_search_detail--package-1 .gnav_search_detail--label:last-child, .gnav_search_detail--package-1 .gnav_search_detail--label:nth-last-child(2), .gnav_search_detail--package-2 .gnav_search_detail--label:last-child, .gnav_search_detail--package-2 .gnav_search_detail--label:nth-last-child(2) {
    border-bottom: none;
  }
  .gnav_search_detail--package-3 .gnav_search_detail--label:last-child, .gnav_search_detail--package-4 .gnav_search_detail--label:last-child {
    border-bottom: none;
  }
  .gnav_search_detail--2rows {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
  }
  .gnav_search_detail--label {
    width: 33%;
    box-sizing: border-box;
    padding: 10px 2px;
    border-right: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.2;
    color: #505050;
    cursor: pointer;
    transition: .15s;
  }
  .gnav_search_detail--label:nth-child(3n) {
    border-right: none;
  }
  .gnav_search_detail--label:hover {
    color: #cca300;
  }
  .gnav_search_detail--checkbox {
    position: relative;
    border: 1px solid #505050;
    appearance: none;
    cursor: pointer;
    width: 16px;
    height: 16px;
    margin-right: 5px;
    transition: .15s;
  }
  .gnav_search_detail--checkbox:after {
    position: absolute;
    top: 2px;
    left: 2px;
    background-color: #cca300;
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    transform: scale(0, 0);
  }
  .gnav_search_detail--checkbox:checked:after {
    animation: inputFade 0.2s cubic-bezier(0.895, 0.03, 0.685, 0.22) forwards;
  }
  @keyframes inputFade {
    0% {
      transform: scale(0, 0);
    }
    100% {
      transform: scale(1, 1);
    }
  }
  .gnav_search_detail--select_box {
    position: relative;
    display: block;
    margin: 0 10px 10px 0;
    width: calc(50% - 10px);
    height: 44px;
    background-color: #eeeae6;
    overflow: hidden;
  }
  .gnav_search_detail--select_box:before {
    position: absolute;
    top: 8px;
    right: 10px;
    content: "";
    display: block;
    border-style: solid;
    border-width: 0 6px 6px 6px;
    border-color: transparent transparent #725c50 transparent;
  }
  .gnav_search_detail--select_box:after {
    position: absolute;
    right: 10px;
    bottom: 8px;
    content: "";
    display: block;
    border-style: solid;
    border-width: 6px 6px 0 6px;
    border-color: #725c50 transparent transparent transparent;
  }
  .gnav_search_detail--select_box:nth-child(2n) {
    margin-right: 0;
  }
  .gnav_search_detail--select_box-wide {
    width: 100%;
  }
  .gnav_search_detail--select {
    font-size: 16px;
    font-size: 1rem;
    padding: 5px 10px;
    background-color: transparent;
    appearance: none;
    width: 100%;
    height: 44px;
    box-sizing: border-box;
    border: 0;
    overflow: hidden;
  }
  .gnav_search_detail--input_box {
    display: flex;
  }
  .gnav_search_detail--input {
    display: inline-block;
    font-size: 14px;
    font-size: 0.875rem;
    padding: 5px 10px;
    margin: 0 10px 10px 0;
    width: calc(50% - 5px);
    height: 44px;
    background-color: #eeeae6;
    box-sizing: border-box;
    border: 0;
    position: relative;
  }
  .gnav_search_detail--type {
    position: relative;
    display: inline-block;
    font-size: 16px;
    font-size: 1rem;
    font-weight: 700;
    top: 15px;
  }
  .gnav_search_detail--txt {
    display: inline-block;
    position: relative;
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.3;
    width: 40%;
    margin-left: 10px;
  }
  .gnav_search_detail--fixedarea {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #42210B;
    padding: 10px 0;
    width: 100%;
  }
  .gnav_search_detail--submit_box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: 50px;
    margin: 0 auto;
    border-radius: 25px;
    background-color: #ffcc00;
    transition: .15s;
  }
  .gnav_search_detail--submit_box:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 30px;
    width: 7px;
    height: 7px;
    margin: auto 0;
    border-top: 2px solid #603813;
    border-right: 2px solid #603813;
    transform: rotate(45deg);
    transition: .15s;
  }
  .gnav_search_detail--submit_box:hover {
    filter: contrast(120%);
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.3);
  }
  .gnav_search_detail--submit {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 700;
    background-color: transparent;
    width: 100%;
    border: 0;
    color: #603813;
    appearance: none;
    height: 50px;
    cursor: pointer;
    transition: .15s;
  }
}

.gnav_fukidashi {
  position: relative;
  display: inline-block;
  min-width: 85px;
  background-color: #42210B;
  border-radius: 5px;
  padding: 5px;
  margin: 0 auto 5px auto;
  color: #ffcc00;
  transition: .15s;
}

.gnav_fukidashi:after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  border-color: #42210B transparent transparent transparent;
  transition: .15s;
}

.gnav--link:hover .gnav_fukidashi,
.gnav_sp_submenu:hover .gnav_fukidashi {
  background-color: #f16b24;
}

.gnav--link:hover .gnav_fukidashi:after,
.gnav_sp_submenu:hover .gnav_fukidashi:after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  border-color: #f16b24 transparent transparent transparent;
}

@font-face {
  font-family: "icomoon";
  font-weight: normal;
  font-style: normal;
  font-display: block;
  src: url("/container/common/fonts/icomoon.eot?11ivf4");
  src: url("/container/common/fonts/icomoon.eot?11ivf4#iefix") format("embedded-opentype"), url("/container/common/fonts/icomoon.ttf?11ivf4") format("truetype"), url("/container/common/fonts/icomoon.woff?11ivf4") format("woff"), url("/container/common/fonts/icomoon.svg?11ivf4#icomoon") format("svg");
}

.contents {
  position: relative;
  width: 100%;
}

.contents-cmn {
  background-image: url("/container/common/images/bg-main-content.png");
}

@media (max-width: 425px) {
  .contents-cmn {
    padding-bottom: 30px;
  }
}

.contents-index {
  background-image: url("/container/common/images/bg-main-content-base.png");
}

.contents--inner {
  width: 960px;
  margin: 0 auto;
}

@media (max-width: 425px) {
  .contents--inner {
    width: 100%;
  }
}

.breadcrumb {
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  display: flex;
  width: 960px;
  margin: 0 auto;
  z-index: 1;
}

@media (max-width: 425px) {
  .breadcrumb {
    width: 100%;
    top: auto;
    bottom: 0;
    overflow-x: scroll;
    white-space: nowrap;
    background-color: #fff;
    border-top: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
    box-sizing: border-box;
  }
}

.breadcrumb-white .breadcrumb--link {
  color: #fff;
}

.breadcrumb-white .breadcrumb--link:after {
  border-top-color: #fff;
  border-right-color: #fff;
}

.breadcrumb-white .breadcrumb--item {
  color: #fff;
}

@media (max-width: 425px) {
  .breadcrumb-white .breadcrumb--link {
    color: #666;
  }
  .breadcrumb-white .breadcrumb--link:after {
    border-top-color: #666;
    border-right-color: #666;
  }
  .breadcrumb-white .breadcrumb--item {
    color: #666;
  }
}

.breadcrumb-brown .breadcrumb--link {
  color: #593A0E;
}

.breadcrumb-brown .breadcrumb--link:after {
  border-top-color: #593A0E;
  border-right-color: #593A0E;
}

.breadcrumb-brown .breadcrumb--item {
  color: #593A0E;
}

@media (max-width: 425px) {
  .breadcrumb-brown .breadcrumb--link {
    color: #666;
  }
  .breadcrumb-brown .breadcrumb--link:after {
    border-top-color: #666;
    border-right-color: #666;
  }
  .breadcrumb-brown .breadcrumb--item {
    color: #666;
  }
}

@media (max-width: 425px) {
  .breadcrumb--inner {
    display: inline-block;
    padding: 10px;
  }
}

.breadcrumb--link {
  position: relative;
  display: inline-block;
  color: #666;
  font-size: 12px;
  font-size: 0.75rem;
  text-decoration: none;
  padding: 0 15px 0 0;
}

.breadcrumb--link:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 5px;
  width: 5px;
  height: 5px;
  border-top: 1px solid #666;
  border-right: 1px solid #666;
  transform: rotate(45deg);
}

.breadcrumb--item {
  position: relative;
  display: inline-block;
  color: #666;
  font-size: 12px;
  font-size: 0.75rem;
}

/* 病院食「献立づくりのコツとポイント」「疾病別展開食のポイント」shadowbox ---------------------------------------------------- */
#sb-title-inner, #sb-info-inner, #sb-loading-inner, div.sb-message {
  font-family: "HelveticaNeue-Light","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-weight: 200;
  color: #fff;
}

#sb-container {
  position: fixed;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  z-index: 999;
  text-align: left;
  visibility: hidden;
  display: none;
}

#sb-overlay {
  position: relative;
  height: 100%;
  width: 100%;
}

#sb-wrapper {
  position: absolute;
  visibility: hidden;
  width: 100px;
}

#sb-wrapper-inner {
  position: relative;
  border: 1px solid #ccc;
}

#sb-body {
  position: relative;
  height: 100%;
}

#sb-body-inner {
  position: absolute;
  height: 100%;
  width: 100%;
}

#sb-player.html {
  height: 100%;
  overflow: hidden;
}

#sb-body img {
  border: none;
}

#sb-loading {
  position: relative;
  height: 100%;
}

#sb-loading-inner {
  position: absolute;
  font-size: 12px;
  line-height: 32px;
  height: 32px;
  top: 50%;
  margin-top: -12px;
  width: 100%;
  text-align: center;
}

#sb-loading-inner span {
  background: url(/hospital-food/js/shadowbox/loading.gif) no-repeat;
  padding-left: 32px;
  display: inline-block;
}

#sb-body, #sb-loading {
  background: #fff;
}

#sb-title, #sb-info {
  position: relative;
  margin: 0;
  padding: 0;
}

#sb-title-inner {
  font-size: 12px;
}

#sb-info, #sb-info-inner {
  width: 100%;
}

#sb-nav {
  position: relative;
  width: 100%;
}

#sb-nav a {
  display: block;
  cursor: pointer;
  background: no-repeat;
}

#sb-nav-close {
  background: url(/hospital-food/js/shadowbox/btn_close.png) !important;
  width: 71px;
  height: 27px;
  margin: 0 auto;
}

#sb-nav-close:hover {
  background: url(/hospital-food/js/shadowbox/btn_close-on.png) !important;
}

#sb-counter {
  display: none;
}

div.sb-message {
  display: none;
}

div.sb-message a:link, div.sb-message a:visited {
  display: none;
}
