@charset "UTF-8";
@import "../mixin";

// See sidenav.inc

//最初のナビゲーション幅
$nav-width: 130px;
//2列目のナビゲーション幅
$nav2nd-width: 260px;
//3列目のナビゲーション幅
$nav3rd-width: calc(100% - (#{$nav-width} + #{$nav2nd-width}));

// PC用ナビゲーション
.gnav {
  position: fixed;
  top:0;
  left:0;
  display: block;
  width: $nav-width;
  height: 100%;
  z-index: 110;
  @include laptop {
    pointer-events: none;
  }
  @include sp {
    display: none;
  }
  &--bg {
    position: relative;
    display: block;
    background-image: url("/container/common/images/cmn-sidemenu-bg.png");
    box-shadow: -1px -1px 3px 1px rgba(0, 0, 0, 0.3) inset;
    width: $nav-width;
    height: calc(100% + 1px); //縦影切落
    z-index: 5;
    @include laptop {
      width: 15px;
      transition: .15s;
      &.slide {
        width: $nav-width;
        .gnav--inner {
          opacity: 1;
          pointer-events: auto;
        }
      }
    }
  }
  &--inner {
    display: block;
    @include laptop {
      position: relative;
      opacity: 0;
      pointer-events: none;
      transition: .3s;
      z-index: 100;
    }
    @include sp {
      display: none;
    }
  }
  &--tab {
    display: none;
    @include laptop {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 10px;
      margin: auto 0;
      width: 30px;
      height: 60px;
      border-radius: 0 30px 30px 0;
      background-image: url("/container/common/images/cmn-sidemenu-bg.png");
      box-shadow: 3px 0 1px 1px rgba(0, 0, 0, 0.3);
      cursor: pointer;
      pointer-events: auto;
      transition: .15s;
      z-index: 6;
      &:before {
        content: "";
        position: absolute;
        top:0;
        bottom:0;
        left: 5px;
        margin: auto 0;
        width: 10px;
        height: 10px;
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
        transform: rotate(45deg);
      }
      &.slide {
        left: calc(#{$nav-width} - 5px);
        &:before {
          transform: rotate(-135deg);
        }
      }
      &.hidden {
        opacity: 0;
        pointer-events: none;
      }
    }
    @include sp {
      display: none;
    }
  }
  &--close {
    display: none;
    position: absolute;
    top: 0;
    right: -36px;
    width: 36px;
    height: 36px;
    cursor: pointer;
    background-color: $colorSidenav;
    z-index: 10;
    &:before,
    &:after {
      display: block;
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 86%;
      height: 4%;
      margin: -2% 0 0 -43%;
      background-color: #fff;
    }
    &:before {
      transform: rotate(-45deg);
    }
    &:after {
      transform: rotate(45deg);
    }
    &-on {
      display: block;
    }
  }
  &--link {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    //min-height: 70px;
    @include fz(12);
    line-height: 1.3;
    font-weight: 700;
    text-align: center;
    color: $colorSidenav;
    text-decoration: none;
    padding: 7px 0;
    box-sizing: border-box;
    border:0;
    background-color: transparent;
    cursor: pointer;
    transition: .15s;
    &:before {
      position: relative;
      @include icon2;
      padding-bottom: 5px;
    }
    &:hover {
      color: #f16b24;
    }
    &.active {
      background-color: $colorSidenav;
      color: #f16b24;
    }
    &-home {
      padding-top:10px;
      &:before {
        content: $icon-home;
        @include fz(22);
      }
    }
    &-navi {
      &:before {
        content: $icon-beginner;
        @include fz(22);
      }
    }
    &-tech {
      &:before {
        content: $icon-tech;
        @include fz(25);
      }
    }
    &-case {
      &:before {
        content: $icon-case;
        @include fz(25);
      }
    }
    &-labo {
      &:before {
        content: $icon-labo;
        @include fz(27);
      }
    }
    &-hospitalfood {
      &:before {
        content: $icon-hospitalfood;
        @include fz(32);
      }
    }
    &-testkitchen {
      &:before {
        content: $icon-testkitchen;
        @include fz(21);
      }
    }
    &-mini {
      padding-top:10px;
    }
  }
}

.gnav_box_2nd {
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top:0;
  left:130px;
  width: 0;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  background-color: $colorSidenav;
  color: $colorYellow;
  transition-duration: .3s;
  z-index: 10;
  &.open {
    opacity: 1;
    pointer-events: auto;
    width: $nav2nd-width;
    .gnav_box_2nd--wrap {
      opacity: 1
    }
  }
  &--wrap {
    opacity: 0;
    transition-duration: .15s;
    transition-delay: .3s;
  }
  &--category {
    position: relative;
    display: block;
    color: $colorYellow;
    text-decoration: none;
    @include fz(15);
    line-height: 1.3;
    font-weight: bold;
    margin: 10px 0;
    padding: 15px 0;
    transition: .15s;
    &:after {
      content: "";
      position: absolute;
      top:0;
      bottom:0;
      margin: auto 0;
      right: 10px;
      width: 5px;
      height: 5px;
      border-top: 2px solid $colorYellow;
      border-right: 2px solid $colorYellow;
      transform: rotate(45deg);
    }
    &:hover {
      background-color: $colorYellow;
      border-radius: 5px;
      color: $colorSidenav;
      &:after {
        border-top-color: $colorSidenav;
        border-right-color: $colorSidenav;
      }
    }
    &:before {
      position: relative;
      @include icon2;
      margin: 0 10px 0 20px;
      vertical-align: middle;
    }
    &-navi {
      &:before {
        content: $icon-beginner;
        @include fz(25);
      }
    }
    &-tech {
      &:before {
        content: $icon-tech;
        @include fz(25);
      }
    }
    &-case {
      &:before {
        content: $icon-case;
        @include fz(25);
      }
    }
    &-labo {
      &:before {
        content: $icon-labo;
        @include fz(28);
      }
    }
    &-hospitalfood {
      &:before {
        content: $icon-hospitalfood;
        @include fz(32);
        top: -3px;
      }
    }
    &-testkitchen {
      &:before {
        content: $icon-testkitchen;
        @include fz(22);
        top: -3px;
      }
    }
  }
  &--ttl {
    background-color: $colorBrown;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    color: #fff;
    padding: 10px;
    margin-top: 15px;
    margin-bottom: 5px;
    @include fz(14);
    font-weight: normal;
  }
  &--linkitem {
    display: block;
    border-bottom: 1px dotted #efebe8;
    text-decoration: none;
    &.active {
      .gnav_box_2nd--ttl_sub {
        background-color: $colorYellow;
        border-radius: 5px;
        color: $colorSidenav;
        &:before {
          background-color: $colorBrown;
        }
        &:after {
          border-top-color: $colorBrown;
          border-right-color: $colorBrown;
          transform: rotate(-135deg);
          right:12px;
        }
      }
    }
    &-topline {
      border-top: 1px dotted #efebe8;
    }
  }
  &--ttl_sub {
    position: relative;
    display: block;
    @include fz(14);
    line-height: 1.2;
    color: #fff;
    font-weight: normal;
    padding: 10px;
    margin-top:5px;
    margin-bottom: 5px;
    transition: .3s;
    &:before,
    &:after {
      content: "";
      position: absolute;
      top:0;
      bottom:0;
      margin: auto 0;
      right:10px;
    }
    &:before {
      width: 10px;
      height: 1px;
      background-color: #fff;
    }
    &:after {
      width: 6px;
      height: 6px;
      border-top: 1px solid #fff;
      border-right: 1px solid #fff;
      transform: rotate(45deg);
    }
    &:hover {
      background-color: $colorYellow;
      border-radius: 5px;
      color: $colorSidenav;
      &:before {
        background-color: $colorBrown;
      }
      &:after {
        border-top-color: $colorBrown;
        border-right-color: $colorBrown;
      }
    }
  }
  &--link {
    position: relative;
    display: block;
    color: #fff;
    text-decoration: none;
    @include fz(14);
    line-height: 1.3;
    padding: 5px 15px 5px 7px;
    transition: .15s;
    &:after {
      content: "";
      position: absolute;
      top:0;
      bottom:0;
      margin: auto 0;
      right: 10px;
      width: 5px;
      height: 5px;
      border-top: 1px solid #fff;
      border-right: 1px solid #fff;
      transform: rotate(45deg);
    }
    &:hover {
      color: $colorYellow;
      &:after {
        border-top-color: $colorYellow;
        border-right-color: $colorYellow;
      }
    }
  }
}

.gnav_box_3rd {
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top:0;
  left: calc(#{$nav-width} + #{$nav2nd-width});
  width: 0;
  height: 100%;
  padding: 30px 0 0 60px;
  box-sizing: border-box;
  background-color: #fff;
  color: $colorBrown;
  transition-duration: .3s;
  &.open {
    opacity: 1;
    pointer-events: auto;
    width: $nav3rd-width;
    .gnav_box_3rd--wrap {
      opacity: 1
    }
  }
  @include laptop {
    overflow-y:scroll;
  }
  &--wrap {
    opacity: 0;
    transition-duration: .15s;
    transition-delay: .3s;
  }
  &--category {
    color: $colorBrown;
    font-weight: 700;
    margin: 0 0 40px 0;
  }
  &--category_name1 {
    @include fz(13);
    padding-bottom:10px;
  }
  &--category_name2 {
    @include fz(17);
  }
  &--inner {
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    max-width: 760px;
  }
  &--innerbox {
    width: 32%;
    margin-right: 2%;
    &:last-child {
      margin-right: 0;
    }
    @include laptop {
      width: 46%;
      margin-right: 2%;
      margin-bottom:20px;
    }
    &-forlaptop {
      @include laptop {
        width: 100%;
        display: flex;
      }
    }
  }
  &--innerbox_forlaptop {
    margin-bottom:20px;
    @include laptop {
      width: 46%;
      margin-right: 2%;
    }
  }
  &--ttl {
    display: flex;
    align-items: center;
    min-height: 40px;
    background-color: $colorYellow;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    color: $colorBrown;
    padding: 0 10px;
    margin: 30px 0 10px 0;
    @include fz(14);
    line-height: 1.2;
    font-weight: 400;
    border-bottom: 1px solid $colorBrown;
    &:first-of-type {
      margin-top:0;
    }
  }
  &--ttl2 {
    color: $colorBrown;
    @include fz(15);
    font-weight: 700;
    margin-top: 25px;
    margin-bottom: 10px;
    padding: 0 0 0 5px;
  }
  &--link {
    position: relative;
    display: block;
    color: #000;
    text-decoration: none;
    @include fz(14);
    line-height: 1.3;
    padding: 10px 5px;
    transition: .2s;
    &:after {
      content: "";
      position: absolute;
      top:0;
      bottom:0;
      margin: auto 0;
      right: 5px;
      width: 5px;
      height: 5px;
      border-top: 1px solid #000;
      border-right: 1px solid #000;
      transform: rotate(45deg);
    }
    &:hover {
      color: $colorBrown;
      &:after {
        border-top-color: $colorBrown;
        border-right-color: $colorBrown;
      }
    }
  }
}

.gnav_sp {
  display: none;
  @include sp {
    position: fixed;
    bottom:0;
    left:0;
    display: block;
    width: 100%;
    height: 60px;
    background-image: url("/container/common/images/cmn-sidemenu-bg.png");
    box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.3) inset;
    z-index: 110;
  }
  &--mainmenu {
    display: flex;
    align-items: center;
    justify-content: center;
    // pagetopボタン＋余白分を除く
    width: calc(100% - 70px - 5px);
    height: 100%;
  }
  &--menubtn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-width: 60px;
    cursor: pointer;
    @include fz(10);
    line-height: 1.1;
    font-weight: 700;
    color: $colorBrown;
  }
  &--menubtn_icon {
    position: relative;
    width: 17px;
    height: 17px;
    border-bottom:2px solid $colorBrown;
    box-sizing: border-box;
    margin: 5px 0;
    &:before,
    &:after {
      content: "";
      position: absolute;
      display: block;
      left:0;
      width: 100%;
      height: 2px;
      background-color: $colorBrown;
    }
    &:before {
      top:0;
    }
    &:after {
      top: calc(50% - 1px);
    }
  }
  &--mainlink {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: calc(100% / 4);
    min-width: 60px;
    @include fz(10);
    line-height: 1.1;
    font-weight: 700;
    text-decoration: none;
    color: $colorBrown;
    text-align: center;
    &:before {
      position: relative;
      @include icon2;
      padding-bottom: 5px;
    }
    &-home {
      &:before {
        content: $icon-home;
        @include fz(22);
      }
    }
    &-beginner {
      &:before {
        content: $icon-beginner;
        @include fz(22);
      }
    }
    &-search {
      &:before {
        content: $icon-recipesearch;
        @include fz(22);
      }
    }
  }
}

//SPメニューから飛び出すコンテンツ
.gnav_sp_contents {
  display: none;
  @include sp {
    //↓.bnrboxに影響するのでdisplay:none にはしないこと
    display: block;
    height: 0;
    opacity: 0;
    pointer-events: none;
    overflow: hidden;
    z-index: -1;
    transition: .3s;
    &.open {
      position: fixed;
      bottom:0;
      left:0;
      width: 100%;
      height: 100%;
      background-color: #fff;
      opacity: 1;
      pointer-events: auto;
      overflow-y: scroll;
      z-index: 150;
    }
    &--inenr {
      position: relative;
      margin-top:50px;
    }
    &--bar {
      position: fixed;
      top:0;
      left:0;
      width: 100%;
      height: 50px;
      background-color: $colorSidenav;
    }
    &--close {
      position: absolute;
      top:0;
      right:0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 80px;
      height: 100%;
      cursor: pointer;
      @include fz(10);
      line-height: 1.1;
      color: #fff;
    }
    &--close_icon {
      position: relative;
      width: 20px;
      height: 20px;
      margin: 2px 0;
      &:before {
        display: inline-block;
        content: "";
        position: absolute;
        top: 0;
        left: 9px;
        width: 2px;
        height: 20px;
        background-color: #fff;
        transform: rotate(45deg);
      }
      &:after {
        display: inline-block;
        content: "";
        position: absolute;
        top: 0;
        right: 9px;
        width: 2px;
        height: 20px;
        border-radius: 1px;
        background-color: #fff;
        transform: rotate(-45deg);
      }
    }
    &--ttl {
      background-image: url("/container/common/images/bg-main-content-footer.png");
      padding: 15px 0;
      text-align: center;
      @include fz(16);
      line-height: 1.2;
      font-weight: 400;
      color: #fff;
    }
  }
}

//sp用ナビゲーション
@include sp {
  .gnav_sp_search {
    position: relative;
    background-color: #fff;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 56px;
    padding: 10px 0;
    margin:50px 0 0 0;
    box-sizing: border-box;
    &--counter {
      position: absolute;
      top:auto;
      bottom:auto;
      left: 20px;
      margin: auto 0;
    }
  }
  .gnav_sp_submenu {
    position: relative;
    padding: 15px 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-image: url("/container/common/images/cmn-sidemenu-bg.png");
    &--item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(50% - 20px);
      height: 100px;
      box-sizing: border-box;
    }
    &--link {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: $colorSidenav;
      text-decoration: none;
      text-align: center;
      @include fz(13);
      line-height: 1.2;
      font-weight: 700;
      padding: 10px;
      &:before {
        position: relative;
        @include icon2;
        padding-bottom: 10px;
      }
      &-navi {
        &:before {
          content: $icon-beginner;
          @include fz(30);
        }
      }
      &-tech {
        &:before {
          content: $icon-tech;
          @include fz(32);
        }
      }
      &-case {
        &:before {
          content: $icon-case;
          @include fz(28);
        }
      }
      &-labo {
        &:before {
          content: $icon-labo;
          @include fz(32);
        }
      }
      &-hospitalfood {
        &:before {
          content: $icon-hospitalfood;
          @include fz(34);
        }
      }
      &-testkitchen {
        &:before {
          content: $icon-testkitchen;
          @include fz(25);
        }
      }
    }
  }
  .gnav_sp_footer {
    background-image: url("/container/common/images/bg-main-content-footer.png");
    padding: 20px 0;
    &--bnrlink {
      position: relative;
      display: block;
      width: 320px;
      margin: 0 auto;
    }
    &--bnrimg {
      width: 100%
    }
    &--inner {
      width: 320px;
      margin: 0 auto;
      padding: 20px 0;
      display: flex;
      flex-wrap: wrap;
    }
    &--link {
      position: relative;
      @include fz(14);
      line-height: 1.2;
      color: #fff;
      text-decoration: none;
      padding: 10px 0 10px 10px;
      box-sizing: border-box;
      &:before {
        content: "";
        position: absolute;
        top:0;
        bottom: 0;
        left:0;
        margin: auto 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 4px 0 4px 4px;
        border-color: transparent transparent transparent #fff;
      }
      &:nth-child(2n-1) {
        width: 55%;
      }
      &:nth-child(2n) {
        width: 45%;
      }
    }
  }
  .gnav_search_detail {
    position: relative;
    margin-bottom:90px;
    &--ttl {
      position: relative;
      color:$colorSidenav;
      @include fz(14);
      font-weight: 700;
      padding: 12px 15px;
      background-image: url("/container/common/images/bg-main-content.png");
      border-bottom: 1px solid #fff;
      transition: .15s;
      &.active {
        &:after {
          transform: rotate(-45deg)
        }
      }
      &-toggle {
        &:after {
          content: "";
          position: absolute;
          top:0;
          bottom:0;
          right: 20px;
          margin: auto 0;
          width: 7px;
          height: 7px;
          border-top: 2px solid $colorSidenav;
          border-right: 2px solid $colorSidenav;
          transform: rotate(135deg);
          transition: .15s;
        }
      }
    }
    &--box {
      position: relative;
      box-sizing: border-box;
      opacity: 0;
      pointer-events: none;
      height: 0;
      transition: .3s;
      &.open {
        opacity: 1;
        pointer-events: auto;
        height: auto;
      }
    }
    &--inner {
      padding: 10px 15px 0 15px;
    }
    &--package {
      display: flex;
      flex-wrap: wrap;
      &-1,
      &-2 {
        .gnav_search_detail--label {
          &:last-child,
          &:nth-last-child(2) {
            border-bottom: none;
          }
        }
      }
      &-3,
      &-4 {
        .gnav_search_detail--label {
          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
    &--2rows {
      display: flex;
      flex-wrap: wrap;
      padding: 10px;
    }
    &--label {
      width: 33%;
      box-sizing: border-box;
      padding: 10px 2px;
      border-right: 1px solid #e6e6e6;
      border-bottom: 1px solid #e6e6e6;
      display: flex;
      align-items: center;
      @include fz(14);
      line-height: 1.2;
      color: #505050;
      cursor: pointer;
      transition: .15s;
      &:nth-child(3n) {
        border-right: none;
      }
      &:hover {
        color: darken($colorYellow, 10%);
      }
    }
    &--checkbox {
      position: relative;
      border: 1px solid #505050;
      appearance: none;
      cursor: pointer;
      width: 16px;
      height: 16px;
      margin-right:5px;
      transition: .15s;
      &:after {
        position: absolute;
        top: 2px;
        left: 2px;
        background-color: darken($colorYellow, 10%);
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        transform: scale(0, 0);
      }
      &:checked {
        &:after {
          animation: inputFade .2s cubic-bezier(.895, .03, .685, .22) forwards;
        }
      }
      @keyframes inputFade {
        0% {
          transform: scale(0, 0);
        }
        100% {
          transform: scale(1, 1);
        }
      }
    }
    &--select_box {
      position: relative;
      display: block;
      margin: 0 10px 10px 0;
      width: calc(50% - 10px);
      height: 44px;
      background-color: #eeeae6;
      overflow: hidden;
      &:before {
        position: absolute;
        top: 8px;
        right: 10px;
        content: "";
        display: block;
        border-style: solid;
        border-width: 0 6px 6px 6px;
        border-color: transparent transparent #725c50 transparent;
      }
      &:after {
        position: absolute;
        right: 10px;
        bottom: 8px;
        content: "";
        display: block;
        border-style: solid;
        border-width: 6px 6px 0 6px;
        border-color: #725c50 transparent transparent transparent;
      }
      &:nth-child(2n) {
        margin-right:0;
      }
      &-wide {
        width: 100%;
      }
    }
    &--select {
      @include fz(16);
      padding: 5px 10px;
      background-color: transparent;
      appearance: none;
      width: 100%;
      height: 44px;
      box-sizing: border-box;
      border: 0;
      overflow: hidden;
    }
    &--input_box {
      display: flex;
    }
    &--input {
      display: inline-block;
      @include fz(14);
      padding: 5px 10px;
      margin: 0 10px 10px 0;
      width: calc(50% - 5px);
      height: 44px;
      background-color: #eeeae6;
      box-sizing: border-box;
      border: 0;
      //z-index: 100;
      position: relative;
    }
    &--type {
      position: relative;
      display: inline-block;
      @include fz(16);
      font-weight: 700;
      top: 15px;
    }
    &--txt {
      display: inline-block;
      position: relative;
      @include fz(14);
      line-height: 1.3;
      width: 40%;
      margin-left:10px;
    }
    &--fixedarea {
      position: fixed;
      bottom:0;
      left:0;
      background-color: $colorSidenav;
      padding: 10px 0;
      width: 100%;
    }
    &--submit_box {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 90%;
      height: 50px;
      margin: 0 auto;
      border-radius: 25px;
      background-color: $colorYellow;
      transition: .15s;
      &:after {
        content: "";
        position: absolute;
        top:0;
        bottom:0;
        right: 30px;
        width: 7px;
        height: 7px;
        margin: auto 0;
        border-top: 2px solid $colorBrown;
        border-right: 2px solid $colorBrown;
        transform: rotate(45deg);
        transition: .15s;
      }
      &:hover {
        filter: contrast(120%);
        box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.3);
      }
    }
    &--submit {
      @include fz(18);
      font-weight: 700;
      background-color: transparent;
      width: 100%;
      border: 0;
      color: $colorBrown;
      appearance: none;
      height: 50px;
      cursor: pointer;
      transition: .15s;
    }
  }
}

//PC/SP共通
// きっちんぷらすmini リンクの吹き出し
.gnav_fukidashi {
  position: relative;
  display: inline-block;
  min-width: 85px;
  background-color: $colorSidenav;
  border-radius: 5px;
  padding: 5px;
  margin: 0 auto 5px auto;
  color: $colorYellow;
  transition: .15s;
  &:after {
    content: "";
    position: absolute;
    bottom: -5px;
    left:0;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 4px 0 4px;
    border-color: $colorSidenav transparent transparent transparent;
    transition: .15s;
  }
}
// hover時
.gnav--link:hover,
.gnav_sp_submenu:hover {
  .gnav_fukidashi {
    background-color: #f16b24;
    &:after {
      content: "";
      position: absolute;
      bottom: -5px;
      left:0;
      right: 0;
      margin: 0 auto;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 4px 0 4px;
      border-color: #f16b24 transparent transparent transparent;
    }
  }
}